export function data() {
  let DEFAULT_FORM_VALUES = {
    communicationID: 0,
    description: '',
    compositionMethod: '',
    htmlContent: '',
    documentID: 0,
    mailingID: 0,
    mailingTypeID: 0,
    supportMailDelivery: false,
    supportEmailDelivery: false,
    supportSMSDelivery: false,
    supportPushDelivery: false,
    isLegalCommunication: false,
    emailSubject: '',
    emailPriority: '',
    smsSubject: '',
    pushSubject: '',
    deliveryAudience: '',
    prevToolTip: '',
    prevPrevented: true,
    prevStep: false,
    nextToolTip: '',
    nextPrevented: false,
    isLastStep: false,
    isSaveUpdated: false
  }

  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    question: '',
    answer: '',
    activeStep: 0,
    communicationID: 0,
    documentID: 0,
    documentFile: null,
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    mailingListUnfiltered: [],
    mailingList: [],
    mailingTypeList: [],
    emailPriorityList: [],
    compositionMethodList: [],
    deliveryAudienceList: [],
    visiblePage: 'Delivery',
    showPreview: false,
    isSent: false,
    toggle: false
  }
}
