<template>
  <div>
    <div v-if="visiblePage === 'Delivery'">
      <div class="subtitle force-center pt-5">How do you want this delivered?</div>
      <div class="force-center mb-5">
        Check any/all, final delivery will be based on the homeowners preferences.
      </div>
      <div class="columns">
        <div class="tile notification is-4 mr-3">
          <div class="media" style="max-height: 70px !important;">
            <div class="media-content" style="overflow: hidden !important;">
              <b-checkbox
                v-model="formData.supportMailDelivery"
                :native-value="formData.supportMailDelivery"
                @click.native="processSupportMail()"
                :disabled="isReadOnly === true"
              ></b-checkbox>
              <div class="force-center">
                <span class="force-center pl-6 is-centered" style="min-width: 300px !important;"
                  >Postal Mail Service</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="tile notification is-4 mr-3">
          <div class="media" style="max-height: 70px !important;">
            <div class="media-content" style="overflow: hidden !important;">
              <b-checkbox
                v-model="formData.supportEmailDelivery"
                :native-value="formData.supportEmailDelivery"
                @click.native="processSupportEmailDelivery"
                :disabled="formData.isLegalCommunication === true || isReadOnly === true"
                :readonly="formData.isLegalCommunication === true || isReadOnly === true"
              >
              </b-checkbox>
              <div class="force-center">
                <span class="force-center pl-6 is-centered" style="min-width: 300px !important;"
                  >Email</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="tile notification is-4 mr-3">
          <div class="media" style="max-height: 70px !important;">
            <div class="media-content" style="overflow: hidden !important;">
              <b-checkbox
                v-model="formData.supportSMSDelivery"
                :native-value="formData.supportSMSDelivery"
                @click.native="processSupportSMSDelivery"
                :disabled="formData.isLegalCommunication === true || isReadOnly === true"
                :readonly="formData.isLegalCommunication === true || isReadOnly === true"
              ></b-checkbox>
              <div class="force-center">
                <span class="force-center pl-6 is-centered" style="min-width: 300px !important;"
                  >SMS</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class=""></div>
      </div>
      <div class="column is-12 force-center">
        <b-checkbox
          v-model="formData.isLegalCommunication"
          :native-value="formData.isLegalCommunication"
          @click.native="processIsLegalCommunication"
          :disabled="isReadOnly === true"
        >
          <div>&nbsp;</div>
          <div>Is this a legal communication that requires delivery via US Postal Mail?</div>
          <div class="small-red-text">
            For legal communications, postal communication is mandatory.
          </div>
        </b-checkbox>
      </div>

      <div class="column is-12">
        <div>
          <b-field label="Description">
            <valid-input
              style="width:50px !important;"
              name="Description"
              type="textarea"
              vid="Description"
              placeholder="Description"
              aria-label="Description"
              rules="required|max:250"
              class="is-small"
              v-model="formData.description"
              :disabled="isReadOnly === true"
            />
          </b-field>
        </div>
        <div class="column is-12 pb-5" style="margin-left: -10px" v-if="mailingTypeList">
          <valid-select
            label="Mailing Type"
            placeholder="Mailing Type"
            vid="mailingTypeID"
            required="true"
            rules="required"
            v-model="formData.mailingTypeID"
            @click.native="changeMailingsByMailType()"
          >
            <option v-for="option in mailingTypeList" :value="option.value" :key="option.value">{{
              option.label
            }}</option>
          </valid-select>
        </div>
        <div class="pt-3">
          <b-button
            :disabled="isReadOnly === true"
            type="is-primary is-small"
            @click.prevent="saveDescription"
            >Save</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { data } from '../keys/data'
import { mapState, mapGetters } from 'vuex'
import ValidSelect from '@/components/inputs/ValidSelect'
import ValidInput from '@/components/inputs/ValidInput'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import { notifyMessage, notifyProblem } from '@/services/notify'

export default {
  props: {
    communication: Object,
    isReadOnly: Boolean,
    step: Number,
    checkSavedPreventions: Function
  },

  name: 'DeliveryMethods',
  data,
  methods: {
    async saveDescription() {
      console.debug('in saveDescription...')

      if (this.formData.isLegalCommunication === true) {
        this.formData.supportEmailDelivery = false
        this.formData.supportSMSDelivery = false
      }

      if (
        this.supportMailDelivery === false &&
        this.supportEmailDelivery === false &&
        this.supportSMSDelivery === false
      ) {
        notifyProblem(
          'Please select at least one delivery method. You currently do not have a delivery method checked.'
        )
        return
      }

      if (this.formData.description === undefined || !this.formData.description) {
        notifyProblem('A description is required.')
        return
      }

      if (this.formData.mailingTypeID === undefined || this.formData.mailingTypeID === 0) {
        notifyProblem('A mailing type selection is required.')
        return
      }

      this.$store.dispatch('communication/saveDescription', {
        communicationDescription: this.formData.description
      })

      if (
        this.communication !== undefined &&
        this.communication &&
        this.communication.communicationID !== undefined &&
        this.communication.communicationID &&
        this.communication.communicationID > 0
      ) {
        const params = {
          communicationID: this.communication.communicationID,
          description: this.formData.description,
          mailingTypeID: this.formData.mailingTypeID
        }

        console.debug('in saveDeliveryMethods() params=' + JSON.stringify(params))

        await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
          params,
          done: async ({ detail }) => {
            if (detail) {
              notifyMessage('Your selections have been saved')
            }
          }
        })
      } else {
        //Add new communication
        await this.addCommunication()
      }

      this.checkSavedPreventions()
    },

    async addCommunication() {
      console.debug(
        'in saveDescription adding communication...' +
          this.communicationID +
          ', ' +
          this.mailingTypeID
      )
      if (
        this.communicationID <= 0 &&
        !this.communication &&
        this.mailingTypeID !== undefined &&
        this.mailingTypeID &&
        this.mailingTypeID > 0
      ) {
        const payload = {
          hoaID: this.hoaId,
          compositionMethod: 'html',
          htmlContent: '',
          htmlEmailContent: '',
          htmlSmsContent: '',
          documentID: null,
          mailingTypeID: this.formData.mailingTypeID || this.mailingTypeID,
          supportMailDelivery: this.supportMailDelivery,
          supportEmailDelivery: this.supportEmailDelivery,
          supportSMSDelivery: this.supportSMSDelivery,
          supportPushDelivery: this.supportPushDelivery,
          isLegalCommunication: this.isLegalCommunication,
          emailSubject: '',
          emailPriority: '',
          smsSubject: '',
          pushSubject: '',
          deliveryAudience: '',
          description: this.formData.description || 'In Progress...'
        }

        await mailingCommunicationStore.dispatch('addCommunication', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              console.debug('add communication=' + JSON.stringify(detail))

              this.communicationID = detail.communicationID

              this.$store.dispatch('communication/saveSessionCommunicationID', {
                mailingCommunicationID: this.communicationID
              })

              notifyMessage('These selections have been saved.')
            }
          }
        })

        this.checkSavedPreventions()
      }
    },

    processSupportMail() {
      console.debug('processSupportMail...')
      this.$store
        .dispatch('communication/saveSupportMailDelivery', {
          supportMailDelivery: !this.formData.supportMailDelivery
        })
        .then(() => {
          this.saveDeliveryMethods(!this.isLegalCommunication)

          //validate after the save
          console.debug('this.supportMailDelivery=' + JSON.stringify(this.supportMailDelivery))
        })
    },

    processSupportEmailDelivery() {
      console.debug('processSupportEmailDelivery...')
      this.$store
        .dispatch('communication/saveSupportEmailDelivery', {
          supportEmailDelivery: this.formData.isLegalCommunication
            ? false
            : !this.formData.supportEmailDelivery
        })
        .then(() => {
          this.saveDeliveryMethods(!this.isLegalCommunication)

          //validate after the save
          console.debug('this.supportEmailDelivery=' + JSON.stringify(this.supportEmailDelivery))
        })
    },

    processSupportSMSDelivery() {
      this.$store
        .dispatch('communication/saveSupportSMSDelivery', {
          supportSMSDelivery: this.formData.isLegalCommunication
            ? false
            : !this.formData.supportSMSDelivery
        })
        .then(() => {
          this.saveDeliveryMethods(!this.isLegalCommunication)

          console.debug('this.supportSMSDelivery=' + JSON.stringify(this.supportSMSDelivery))
        })
    },

    processIsLegalCommunication() {
      this.$store
        .dispatch('communication/saveIsLegalCommunication', {
          isLegalCommunication: !this.formData.isLegalCommunication
        })
        .then(() => {
          console.debug('processSupportMail...')
          this.formData.supportMailDelivery = true
          this.$store
            .dispatch('communication/saveSupportMailDelivery', {
              supportMailDelivery: true
            })
            .then(() => {
              this.formData.supportSMSDelivery = false
              this.$store
                .dispatch('communication/saveSupportEmailDelivery', {
                  supportEmailDelivery: false
                })
                .then(() => {
                  this.formData.supportEmailDelivery = false
                  this.$store
                    .dispatch('communication/saveSupportSMSDelivery', {
                      supportSMSDelivery: false
                    })
                    .then(() => {
                      console.debug(
                        'this.isLegalCommunication=' + JSON.stringify(this.isLegalCommunication)
                      )
                      this.saveDeliveryMethods(!this.isLegalCommunication)
                    })
                })
            })
        })
    },

    async saveDeliveryMethods(isLegal) {
      if (
        this.communication !== undefined &&
        this.communication &&
        this.communication.communicationID !== undefined &&
        this.communication.communicationID > 0
      ) {
        let params = {}

        params = {
          communicationID: this.communication.communicationID,
          supportMailDelivery: this.supportMailDelivery,
          supportEmailDelivery: !isLegal === false ? this.supportEmailDelivery : false,
          supportSMSDelivery: !isLegal === false ? this.supportSMSDelivery : false,
          mailFullDuplex: null,
          mailColor: null,
          isLegalCommunication: !isLegal,
          description: this.formData.description
        }

        console.debug('in saveDeliveryMethods() params=' + JSON.stringify(params))

        await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
          params,
          done: async ({ detail }) => {
            if (detail) {
              console.debug(
                'update communication for compose step changed=' + JSON.stringify(detail)
              )
            }
          }
        })
      }
    },

    changeMailingsByMailType() {
      this.$store.dispatch('communication/saveMailingTypeID', {
        mailingTypeID: this.formData.mailingTypeID
      })

      console.debug(
        'this.mailingTypeID=' +
          JSON.stringify(this.formData.mailingTypeID + ', ' + this.mailingTypeID)
      )
    },

    async loadMailingTypeList() {
      await mailingCommunicationStore.dispatch('getMailingTypeList').then(({ list }) => {
        if (list) {
          this.mailingTypeList = list
          this.formData.mailingTypeID = this.mailingID || this.communication.mailingTypeID
          console.debug('loadMailingTypeList...' + this.formData.mailingTypeID)

          if (this.formData.mailingTypeID) {
            this.$store.dispatch('communication/saveMailingTypeID', {
              mailingTypeID: this.formData.mailingTypeID
            })
          }
        }
      })
    },

    populateFromState() {
      let tempDescription = ''
      if (this.formData.description && this.formData.description.length > 0) {
        tempDescription = this.formData.description

        this.$store.dispatch('communication/saveDescription', {
          communicationDescription: this.formData.description
        })
      } else {
        tempDescription = this.communicationDescription
      }

      this.formData.supportMailDelivery = this.supportMailDelivery || false
      this.formData.supportEmailDelivery = this.supportEmailDelivery || false
      this.formData.supportSMSDelivery = this.supportSMSDelivery || false
      this.formData.isLegalCommunication = this.isLegalCommunication || false
      this.formData.mailingTypeID =
        !this.formData.mailingTypeID && this.mailingTypeID
          ? this.mailingTypeID
          : this.formData.mailingTypeID
      this.formData.description = tempDescription
    }
  },
  components: {
    ValidSelect,
    ValidInput
  },
  mounted() {
    this.loadMailingTypeList()

    if (this.communication === undefined || !this.communication) {
      this.$store.dispatch('communication/initStates')
    }

    setTimeout(() => {
      this.populateFromState()
    }, 1000)
  },

  watch: {
    step() {
      console.debug('in watch step=' + this.mailingTypeID)
      setTimeout(() => {
        this.populateFromState()
      }, 1000)
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'communicationDescription',
        'isFullDuplex',
        'isColor'
      ])
    })
  }
}
</script>
